.smart-search-block {
	position: relative;
	margin-top: 13px;
	margin-bottom: 55px;

	.input-wrap {
		margin-bottom: 0;
	}

	&--load {

	}

	&--list {
		.smart-search-list {
			pointer-events: auto;
			opacity: 1;
			transform: translateY(0);
		}
	}
}

.smart-search-input {
	position: relative;
	margin-bottom: 40px;
	width: 780px;
	max-width: 100%;
}

.smart-search-list {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	box-sizing: border-box;
	background: #FFFFFF;
	box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2);
	border-radius: 8px;
	padding: 0 22px;
	z-index: 2;
	margin-top: 12px;
	pointer-events: none;
	opacity: 0;
	transform: translateY(-10px);
	transition: all .3s ease-in-out;

	&-itm {
		position: relative;
		padding: 20px;
		display: block;
		color: inherit;
		text-decoration: none;
		cursor: pointer;

		&:not(:last-child):before {
			content: '';
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			border-bottom: 1px solid rgba(179, 179, 179, 0.3);
		}

		&:hover {
			color: $c_main;
		}

		&.active {
			color: $c_second;
		}
	}
}

.smart-search-result {
	margin-top: 20px;

	> div:not(.smart-search-multiple-result-itm) {
		padding-bottom: 66px;
		border-bottom: 1px solid rgba(179, 179, 179, 0.3);
	}

	.title-mid {
		margin-bottom: 25px;
	}

	.btn {
		width: 380px;
		max-width: 100%;
	}

	&--multi {
		display: flex;
		align-items: center;
		gap: 27px;
		flex-wrap: wrap;
	}
}

.smart-search-multiple-result-itm {
	position: relative;
	min-height: 62px;
	line-height: 1.1;
	box-sizing: border-box;
	border: 1px solid $c_gray_bg;
	border-radius: 8px;
	padding: 6px 52px 6px 20px;
	display: flex;
	align-items: center;
	max-width: 450px;
	.title {
		@include line-clump(1);
	}
}

.multiple-result-remove {
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	padding: 0 15px;
	display: flex;
	align-items: center;
	cursor: pointer;

	&:hover {
		.cross {
			&:before, &:after {
				background: $c_second;
			}
		}
	}

	.cross {
		&:before, &:after {
			background: #D9D9D9;
			transition: all .2s ease-in-out;
		}
	}
}

.remember-list {
	margin: 33px 0 49px;

	.title {
		margin-bottom: 19px;
	}

	&__remove {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			.cross {
				&:before, &:after {
					background: $c_second !important;
				}
			}
		}

		.cross {
			position: relative;
			top: -1px;

			&:before, &:after {
				transition: all .2s ease-in-out;
				background: #D9D9D9 !important;
			}
		}
	}

	&-itm, li {
		position: relative;
		max-width: 300px;

		&.active {
			.remember-list__itm {
				background: $c_main;
				color: #fff;
			}

			.cross {
				&:before, &:after {
					background: #fff;
				}
			}
		}

		.cross {
			&:before, &:after {
				background: $c_gray;
			}
		}
	}

	&-itm {
		.remember-list__itm {
			cursor: auto;
		}
	}

	li {
		&:hover {
			.remember-list__itm {
				background: $c_purple;
				color: #fff;
			}

			.cross {
				&:before, &:after {
					background: #fff;
				}
			}
		}
	}

	ul {
		list-style: none;
		display: flex;
		gap: 26px;
		flex-wrap: wrap;
		align-items: flex-start;
	}

	&__itm {
		cursor: pointer;
		border-radius: 8px;
		background: $c_select_bg;
		text-decoration: none;
		color: $c_gray;
		padding: 12px 30px 12px 12px;
		transition: all .2s ease-in-out;
		display: block;

		&__text{
			@include line-clump(1);
		}
	}
}

.list-item {
	position: relative;

	&.orange {
		.list-item {
			&__name {
				background: $c_second_bg;
			}

			&__remove:hover {
				.cross {
					&:before, &:after {
						background: $c_main !important;
					}
				}
			}
		}

		.cross {
			&:before, &:after {
				background: #fff;
			}
		}

		&:hover, &.active {
			.list-item__name {
				background: $c_second;
				color: #fff;
			}

			.cross {
				&:before, &:after {
					background: #fff;
				}
			}
		}
	}

	&:hover, &.active {
		.list-item__name {
			background: $c_purple;
			color: #fff;
		}

		.cross {
			&:before, &:after {
				background: #fff;
			}
		}
	}


	&__name {
		cursor: pointer;
		border-radius: 8px;
		background: $c_select_bg;
		text-decoration: none;
		color: $c_gray;
		padding: 12px 30px 12px 12px;
		transition: all .2s ease-in-out;
		display: block;
		max-width: 350px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	&__remove {
		position: absolute;
		right: 0;
		top: 0;
		height: 100%;
		padding: 0 10px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;

		&:hover {
			.cross {
				&:before, &:after {
					background: $c_second !important;
				}
			}
		}

		.cross {
			position: relative;
			top: -1px;

			&:before, &:after {
				transition: all .2s ease-in-out;
				background: #D9D9D9 !important;
			}
		}
	}
}
