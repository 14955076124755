.wrapper {
	width: 100%;
	min-height: 90vh;
	overflow: hidden;
}

.main-content {
	padding-top: 57px;
	min-height: calc(100vh - 250px);
	box-sizing: border-box;
}

.tooltip {
	display: inline-block;
	vertical-align: middle;
	margin-left: 20px;
	font-size: 16px;

	&__ico {
		cursor: pointer;
		transition: transform .2s ease-in-out;
		will-change: transform;

		&:hover {
			transform: scale(1.2);
		}
	}

	&__content {
		display: none;
	}
}

.title-flex {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.loader {
	position: fixed;
	left: 0;
	top: 90px;
	right: 0;
	bottom: 0;
	background: $c_light_gray;
	z-index: 2;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	gap: 40px;
	opacity: 0;
	pointer-events: none;
	transition: opacity .3s ease-in-out;

	&.loading {
		opacity: 1;
	}

	&-ico {
		position: relative;
		width: 181px;
		height: 181px;

		&-bg {
			@include pos-center();
		}

		&-dots {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 14px;
		}
	}

	&-text {
		animation: loader-text infinite 1.5s ease-in-out;

	}

	&-dot {
		position: relative;
		width: 51px;
		height: 51px;
		border-radius: 50%;
		background: $c_main;

		&.dot-active {
			position: absolute;
			left: 0;
			top: 0;
			background: $c_second;
			transform: scale(1.01);
			transform-origin: center;
			z-index: 1;
			animation: loader-dot infinite 1.5s cubic-bezier(1, 0.02, 0.04, 1);
		}
	}
}

@keyframes loader-text {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.5;
	}
}

@keyframes loader-dot {
	0% {
		left: 0;
		top: 0;
	}
	12.5% {
		left: 0;
		top: 50%;
		transform: translateY(-50%);
	}
	25% {
		left: 0;
		top: 100%;
		transform: translateY(-100%);
	}
	37.5% {
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
	50% {
		left: 100%;
		top: 0;
		transform: translateY(0) translateX(-100%);
	}
	62.5% {
		left: 100%;
		top: 50%;
		transform: translateY(-50%) translateX(-100%);
	}
	75% {
		left: 100%;
		top: 100%;
		transform: translateY(-100%) translateX(-100%);
	}
	87.5% {
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
	}
}

.form-invite {
	display: flex;
	gap: 20px;
	margin-top: 55px;

	.input-wrap {
		margin-bottom: 0;
	}

	.btn {
		flex-shrink: 0;
	}
}

.modal-invite {
	.modal-content {
		width: 670px;
	}
}

.checkbox {
	position: relative;
	display: block;
	width: 23px;
	height: 23px;
	cursor: pointer;

	input {
		opacity: 0;

		&:checked {
			~ .checkbox__check {
				background: #EFEFFF;
				border-color: #EFEFFF;

				&:after {
					opacity: 1;
					transform: scale(1);
				}
			}
		}
	}

	&__check {
		@include fill();
		border: 1px solid #D9D9D9;
		border-radius: 4px;
		transition: all .3s ease-in-out;

		&:hover {
			background: #EFEFFF;
		}

		&:after {
			content: "";
			position: absolute;
			width: 15px;
			height: 17px;
			top: 50%;
			left: 50%;
			margin-top: -9px;
			margin-left: -8px;
			background: url("../ico/check.svg") no-repeat center;
			background-size: contain;
			opacity: 0;
			transform: scale(0.5);
			transition: all .3s ease-in-out;
			transform-origin: center;
			image-rendering: crisp-edges;
			will-change: transform;
		}
	}
}

.modal {
	@include fill();
	position: fixed;
	z-index: 20;
	display: flex;
	justify-content: center;
	align-items: center;
	pointer-events: none;

	&.active {
		pointer-events: auto;

		.modal-fade, .modal-content {
			opacity: 1;
		}
	}

	&-fade {
		@include fill();
		background: rgba(65, 39, 121, 0.2);
		transition: opacity .3s ease-in-out;
		will-change: opacity;
		cursor: pointer;
		backdrop-filter: blur(8.5px);
		opacity: 0;
	}

	&-close {
		position: absolute;
		left: 100%;
		bottom: 100%;
		padding: 9px;
		cursor: pointer;

		&:hover {
			.cross {
				&:before, &:after {
					background: $c_second;
				}
			}
		}

		.cross {
			width: 31px;
			height: 31px;

			&:before, &:after {
				background: $c_main;
			}
		}
	}

	&-content {
		position: relative;
		background: #fff;
		box-shadow: 0px 10px 21px rgba(111, 90, 173, 0.2);
		border-radius: 8px;
		padding: 50px 60px 80px;
		width: 970px;
		max-width: 90%;
		opacity: 0;
		transition: opacity .3s ease-in-out;
		box-sizing: border-box;
	}

	&--export {
		.modal-content {
			padding: 0;
			width: 670px;
		}
	}
}

.cross{
	cursor: pointer;

	&--text{
		width: auto;
		padding-left: 20px;
		color: $c_gray_bg;
		transition: .3s ease;
		&:hover{
			color: $c_purple;
		}
		&:before, &:after{
			width: 15px;
			left: 0;
		}
	}
}

.stream-rename-modal {

	.modal-content {
		width: 668px;
	}

	&__title {
		margin-bottom: 30px;
	}

	&__btn {
		width: 155px;
		display: block;
		margin: 30px auto 0;
	}
}

.modal-export-top {
	padding: 60px 80px;
	text-align: center;
}

.modal-export-bot {
	padding: 40px 80px 60px;
	border-top: 3px solid #D9D9D9;

	.title {
		margin-bottom: 20px;
	}
}

.more-btn {
	display: flex;
	align-items: center;
	color: $c_main_dark;
	gap: 10px;
	cursor: pointer;
	transition: color .2s ease-in-out;

	svg {
		transform: rotate(90deg);
		transition: all .2s ease-in-out;
		fill: $c_main_dark;
	}

	&:hover {
		color: $c_second;

		svg {
			fill: $c_second;
		}
	}

	&.orange {
		color: $c_second;

		svg {
			fill: $c_second;
		}

		&:hover {
			color: $c_main_dark;

			svg {
				fill: $c_main_dark;
			}
		}
	}


	&.show-all {
		svg {
			transform: rotate(270deg);
		}
	}
}

.stream-table {
	width: 100%;
	margin-bottom: 70px;
	margin-top: 45px;

	th {
		font-weight: 400;
		color: $c_gray;
		white-space: pre-line;

		&:first-child {
			text-align: left;
		}
	}

	td {
		padding: 50px 10px;
		text-align: center;
		border-bottom: 1px solid rgba(179, 179, 179, 0.3);
		vertical-align: top;

		.title {
			max-width: 570px;
			display: inline-block;
		}

		a {
			text-decoration: none;
			transition: color .2s ease-in-out;

			&:hover {
				color: $c_second;
			}
		}

		&:first-child {
			padding-left: 0;
			text-align: left;
		}

		&:last-child {
			padding-right: 0;
		}

		.cross{
			cursor: pointer;
			display: inline-block;
			&:before, &:after{
				background-color: $c_gray_bg;
				transition: .3s ease;
			}
			&:hover{
				&:before, &:after{
					background-color: $c_red;
				}
			}
		}
	}
}

.remember-block {
	display: flex;
	align-items: center;
	gap: 60px;
	margin-bottom: 26px;

	.remember-list-itm {
		display: inline-block;
		max-width: 100%;
	}

	&__itm {
		flex: 1;
	}

	&__link {
		position: relative;
		color: $c_main;

		&:not(:last-child):after {
			content: '';
			position: absolute;
			right: -30px;
			top: 50%;
			height: 40px;
			margin-top: -20px;
			width: 1px;
			background: #D9D9D9;
		}

		a {
			color: inherit;
			text-decoration: none;
			transition: color .2s ease-in-out;

			&:hover {
				color: $c_second;
			}
		}
	}
}

.all-reports-page {
	.list-block-content {
		margin: 0;
	}
}